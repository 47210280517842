<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="10"
    viewBox="0 0 8 10"
  >
    <g fill="none" fill-rule="evenodd">
      <g :fill="iconColor()" fill-rule="nonzero">
        <g>
          <g>
            <path
              d="M6.168 4.48c.48.169.856.451 1.128.847.272.395.408.882.408 1.46 0 .817-.286 1.446-.858 1.886-.572.44-1.406.66-2.502.66H0V0h4.104c1.024 0 1.81.218 2.358.653.548.436.822 1.027.822 1.774 0 .453-.098.857-.294 1.213-.196.356-.47.636-.822.84zM1.932 1.627v2.2h1.932c.48 0 .844-.094 1.092-.28.248-.187.372-.463.372-.827 0-.364-.124-.638-.372-.82-.248-.182-.612-.273-1.092-.273H1.932zM4.2 7.707c.512 0 .898-.094 1.158-.28.26-.187.39-.476.39-.867 0-.773-.516-1.16-1.548-1.16H1.932v2.307H4.2z"
              transform="translate(-913 -125) translate(502 114) translate(411 11)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? '#009fb1' : '#373c43';
    },
  },
};
</script>
