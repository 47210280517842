<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g
            transform="translate(-845 -450) translate(593 413) translate(252 37)"
          >
            <circle cx="12" cy="12" r="12" :fill="iconColor()" />
            <g fill="#FFF" fill-rule="nonzero">
              <g>
                <path
                  d="M1.91 12.7c.133 1.783 1.709 3.164 3.605 3.16h2.97c1.896.004 3.472-1.377 3.606-3.16l.636-9.04h1.23v-1.2H9.822v-.72c0-.884-.76-1.6-1.697-1.6H5.876c-.937 0-1.697.716-1.697 1.6v.72H.042v1.2h1.23l.637 9.04zm8.908-.08c-.089 1.152-1.108 2.043-2.333 2.04h-2.97c-1.225.003-2.244-.888-2.333-2.04l-.637-8.96h8.91l-.637 8.96zM5.452 1.74c0-.22.19-.4.424-.4h2.248c.235 0 .424.18.424.4v.72H5.452v-.72z"
                  transform="translate(5 4)"
                />
                <path
                  d="M4.652 8.42L12.432 8.434 12.434 9.707 4.654 9.693z"
                  transform="translate(5 4) rotate(-88.3 8.543 9.064)"
                />
                <path
                  d="M4.792 5.18L6.065 5.183 6.079 12.963 4.806 12.961z"
                  transform="translate(5 4) rotate(-1.7 5.435 9.072)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['color'],
  methods: {
    iconColor() {
      switch (this.color) {
        case 'red':
          return '#E95E5E';
        case 'blue':
          return '#009fb1';
        default:
          return '#B8B9BB';
      }
    },
  },
};
</script>
