<template>
  <div
    ref="page"
    class="page"
    :style="{
      minWidth: calcWidthPage,
      maxWidth: calcWidthPage,

      minHeight: this.pageHeight + 'px',
      maxHeight: this.pageHeight + 'px',
      backgroundColor: layoutBackground,
      backgroundImage: 'url(' + background() + ')',
      backgroundSize: currentLayout == 'Card' ? 'contain' : 'cover',
      backgroundPosition: 'center center',
      borderRadius: currentLayout === 'Badge' ? '50%' : '0%',
      visibility: currentLayout ? 'visible' : 'hidden',
    }"
    @mousemove="moveActiveComponent"
    @keydown.up.prevent="keyboardActiveComponent('up')"
    @keydown.down.prevent="keyboardActiveComponent('down')"
    @keydown.left="keyboardActiveComponent('left')"
    @keydown.right="keyboardActiveComponent('right')"
    @keydown.delete="keyboardActiveComponent('delete')"
    tabindex="0"
  >
    <!-- Layout -->
    <!--     <component
      :is="currentLayout"
      :highlight="false"
      :background="layoutBackground"
      :style="{
        maxHeight: this.pageHeight + 'px'
      }"
    ></component> -->

    <!-- Components -->
    <div
      class="component"
      v-for="(component, index) in components"
      :key="component.id"
      :style="{
        top: component.y + '%',
        left: component.x + '%',
        padding: '0.5rem',
        minHeight: componentHeight(component),
        minWidth: componentWidth(component),
        zIndex: isQRCode(component) ? components.length : index,
      }"
      :class="{ activeComponent: isActiveComponent(component) }"
      :ref="component.id"
      @mousedown.stop="setActiveComponent($event, component)"
      @mouseup="updateComponent(component)"
      @dblclick="(component.isEditable = true), (iconColorEdit = 'blue')"
    >
      <div
        class="component-options"
        @mousedown="$event.stopPropagation()"
        v-show="isActiveComponent(component)"
      >
        <!-- Delete component icon -->
        <div
          style="display: flex; overflow: visible !important; z-index: 12"
          v-if="!isQRCode(component)"
          @click="deleteComponent(component)"
          @mouseover="iconColorDelete = 'blue'"
          @mouseout="iconColorDelete = ''"
        >
          <div
            style="
              display: flex;
              position: relative;
              overflow: visible !important;
            "
            @mouseover="displayDeleteHover = true"
            @mouseout="displayDeleteHover = false"
          >
            <IconDelete :color="iconColorDelete" />
            <Hover v-show="displayDeleteHover">
              {{ $t('page.delete') }}
            </Hover>
          </div>
        </div>
        <!-- Edit component icon SHOULD USE === instead = 6 lines under-->
        <div
          style="display: flex; overflow: visible !important; z-index: 12"
          v-if="!isQRCode(component)"
          @click="editComponent(component)"
          @mouseover="iconColorEdit = 'blue'"
          @mouseout="
            iconColorEdit = component.isEditable
              ? (iconColorEdit = 'blue')
              : (iconColorEdit = '')
          "
        >
          <div
            style="
              display: flex;
              position: relative;
              overflow: visible !important;
              margin-left: 0.5rem;
            "
            @mouseover="displayEditHover = true"
            @mouseout="displayEditHover = false"
          >
            <IconEdit :color="iconColorEdit" />
            <Hover v-show="displayEditHover">
              {{ $t('page.edit') }}
            </Hover>
          </div>
        </div>
        <!-- Info QRCode icon -->
        <div
          style="display: flex; overflow: visible !important; z-index: 15"
          v-if="isQRCode(component)"
        >
          <div
            style="
              display: flex;
              position: relative;
              overflow: visible !important;
            "
            @mouseover="displayQRCodeHover = true"
            @mouseout="displayQRCodeHover = false"
          >
            <IconInfo />
            <Hover v-show="displayQRCodeHover">
              <p v-html="$t('page.qrcode')"></p>
            </Hover>
          </div>
        </div>
      </div>

      <!-- Text Component -->
      <textarea
        v-if="
          isText(component) &&
          editable &&
          (component.warp == 0 || component.isEditable)
        "
        v-model="component.text"
        :ref="'textarea' + component.id"
        :key="'textarea' + component.id"
        @input="resizeTextArea(component, true)"
        :disabled="!component.isEditable"
        rows="1"
        :style="{
          resize: 'none',
          whiteSpace: 'pre',
          fontFamily: component.fontFamily,
          fontSize: reactiveFontSize(component.fontSize) + 'px',
          fontStyle: component.isItalic ? 'italic' : 'normal',
          fontWeight: component.isBold ? 'bold' : 'normal',
          textDecoration: component.isUnderlined ? 'underline' : 'none',
          textAlign: component.justification,
          color: component.fontColor,
          opacity: component.visible ? '1' : '0',
          overflow: 'visible',
          width: 'auto',
        }"
      ></textarea>

      <!-- Curved Text -->
      <div
        v-else-if="
          isText(component) &&
          !isNaN(component.warp) &&
          component.warp != 0 &&
          !component.isEditable
        "
        style="overflow: visible !important"
        :style="{
          visibility:
            component.warp != 0 && !component.isEditable ? 'visible' : 'hidden',
        }"
        :ref="'textarea' + component.id"
      >
        <svg
          viewBox="0 0 100 100"
          :ref="'curvedtext' + component.id + '0'"
          style="
            position: absolute;
            overflow: visible !important;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        >
          <!-- :stroke="isActiveComponent(component) ? '#e1e1e1' : 'transparent'" -->
          <path
            id="curve"
            fill="transparent"
            :stroke="isActiveComponent(component) ? '#e1e1e1' : 'transparent'"
            stroke-width="3px"
            stroke-dasharray="10%"
            :d="
              component.warp > 0
                ? 'M 50, ' +
                  (50 + Math.abs(component.warp)) +
                  'a ' +
                  Math.abs(component.warp) +
                  ',' +
                  Math.abs(component.warp) +
                  ' 0 1,1 0,' +
                  Math.abs(component.warp) * -2 +
                  'a ' +
                  Math.abs(component.warp) +
                  ',' +
                  Math.abs(component.warp) +
                  ' 0 1,1 0, ' +
                  Math.abs(component.warp) * 2
                : 'M 50, ' +
                  (50 - Math.abs(component.warp)) +
                  'a ' +
                  Math.abs(component.warp) +
                  ',' +
                  Math.abs(component.warp) +
                  ' 0 1,0 0,' +
                  Math.abs(component.warp) * 2 +
                  'a ' +
                  Math.abs(component.warp) +
                  ',' +
                  Math.abs(component.warp) +
                  ' 0 1,0 0, ' +
                  Math.abs(component.warp) * -2
            "
          />

          <text
            :dy="component.warp > 0 ? '-10%' : '110%'"
            width="100"
            class="curved-text"
            :style="{
              fontFamily: component.fontFamily + ' !important',
              fontSize: reactiveFontSize(component.fontSize) * 3.5 + 'px',
              fontStyle: component.isItalic ? 'italic' : 'normal',
              fontWeight: component.isBold ? 'bold' : 'normal',
              textDecoration: component.isUnderlined ? 'underline' : 'none',
              fill: component.fontColor,
              letterSpacing: component.spacing + 'px',
            }"
          >
            <textPath
              text-anchor="middle"
              startOffset="50%"
              xlink:href="#curve"
              :style="{
                fontFamily: component.fontFamily + ' !important',
              }"
            >
              {{ component.text }}
            </textPath>
          </text>
        </svg>
      </div>

      <!-- Required for issuing -->
      <div
        v-else-if="isText(component)"
        :ref="'textarea' + component.id"
        :key="'textarea' + component.id + 'text'"
        @input="resizeTextArea(component, true)"
        :disabled="!component.isEditable"
        tabindex="-1"
        cols="1"
        rows="1"
        :style="{
          resize: 'none',
          whiteSpace: 'pre',
          fontFamily: component.fontFamily,
          fontSize: reactiveFontSize(component.fontSize) + 'px',
          fontStyle: component.isItalic ? 'italic' : 'normal',
          fontWeight: component.isBold ? 'bold' : 'normal',
          textDecoration: component.isUnderlined ? 'underline' : 'none',
          textAlign: component.justification,
          color: component.fontColor,
          opacity: 'visible' in component && !component.visible ? '0' : '1',
          margin: '0.5rem',
        }"
      >
        <input
          style="display: none"
          @input="resizeTextArea(component, true)"
          v-model="component.text"
        />
        {{ component.text }}
      </div>

      <!-- Image Component -->
      <div
        :ref="'image' + component.id"
        v-if="isImage(component)"
        :style="{
          width: renderedDimensions * component.size + 'px',
          resize:
            component.isEditable && component.type === 'image'
              ? 'horizontal'
              : 'none',
        }"
      >
        <img
          :src="component.src"
          alt=""
          style="pointer-events: none"
          :style="{ width: '100%' }"
        />
      </div>

      <!-- Signature Component -->
      <input
        type="text"
        v-if="isSignature(component) && !component.src"
        v-model="component.text"
        :ref="'input' + component.id"
        :key="'textarea' + component.id"
        @input="resizeTextArea(component, false)"
        :disabled="!component.isEditable"
        size="1"
        :style="{
          whiteSpace: 'pre',
          fontSize: reactiveFontSize(component.fontSize) + 'px',
          fontFamily: component.fontFamily + '!important',
          color: component.fontColor,
        }"
      />
      <div
        :ref="'image' + component.id"
        v-if="isSignature(component) && component.src"
        :style="{
          width: renderedDimensions * component.size + 'px',
          resize:
            component.isEditable && component.type === 'signature'
              ? 'horizontal'
              : 'none',
        }"
      >
        <img
          :src="component.src"
          alt=""
          style="pointer-events: none"
          :style="{ width: '100%' }"
        />
      </div>

      <!-- QR Code -->
      <div
        v-if="isQRCode(component)"
        class="qrcode"
        style="
          user-select: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0.5rem;
        "
      >
        <div
          :style="{ fontSize: renderedDimensions * 0.0125 + 'px' }"
          style="margin-bottom: 0.3rem"
        >
          {{ $t('page.qrcodePalceholder') }}
        </div>
        <img
          src="../../assets/qr-code.png"
          alt=""
          style="pointer-events: none; user-select: none"
          :style="{ width: renderedDimensions * 0.1 + 'px' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconDelete from '../../assets/icon-delete-attribute';
import IconEdit from '../../assets/icon-edit-attribute';
import IconInfo from '../../assets/icon-info-big';
import Hover from '../Misc/Hover.vue';
/* import A4 from "../../assets/Layouts/rectangle-a4";
import Badge from "../../assets/Layouts/badge-circular"; */

import { layoutRatios } from '@/consts';

export default {
  props: ['side', 'width', 'editable'],
  components: {
    IconDelete,
    IconEdit,
    IconInfo,
    Hover,
    /*     A4,
    Badge */
  },
  data() {
    return {
      /* 16 - convertion rem to px; heights: 4 - header, 3 - canvas toolbar, 3.5 - footer, 3 * 5 - passepartout padding and add-page height and margin, 2 - border corrections and ???*/
      xDistanceMouseToComponentCenter: 0,
      yDistanceMouseToComponentCenter: 0,
      iconColorEdit: '',
      iconColorDelete: '',
      displayQRCodeHover: false,
      displayDeleteHover: false,
      displayEditHover: false,
    };
  },
  methods: {
    // Recursivity out of control
    componentHeight(component) {
      if (
        this.isText(component) &&
        component.warp != 0 &&
        this.$refs['curvedtext' + component.id + '0'] &&
        component.isEditable
      ) {
        var height =
          this.$refs[
            'curvedtext' + component.id + '0'
          ][0].getBoundingClientRect().height;
        return height >= 60 ? height + 'px' : '3.65rem';
      }
      return 'unset';
    },
    componentWidth(component) {
      if (
        this.isText(component) &&
        component.warp != 0 &&
        this.$refs['curvedtext' + component.id + '0'] &&
        component.isEditable
      ) {
        var width =
          this.$refs[
            'curvedtext' + component.id + '0'
          ][0].getBoundingClientRect().width;
        return width >= 60 ? width + 'px' : '3.65rem';
      }
      return '3.65rem';
    },
    warpAligner(characterId, numberCharacters, warp) {
      var delta;
      if (numberCharacters.length % 2 === 0) {
        if (characterId === numberCharacters / 2) {
          delta = characterId - 0.5;
        } else if (characterId === 1 + numberCharacters / 2) {
          delta = characterId + 0.5;
        } else {
          delta = characterId + 0.5 - numberCharacters.length / 2;
        }
      } else {
        delta = characterId - Math.floor(numberCharacters.length / 2);
      }
      return warp > 0 ? delta : -delta;
    },
    characters(string) {
      var characters = string.split('');
      var indexedCharacters = [];
      for (let i = 0; i < characters.length; i++) {
        indexedCharacters.push({ character: characters[i], id: i });
      }
      return indexedCharacters;
    },
    updateComponent(component) {
      if (component.type === 'image' && component.isEditable) {
        component.size =
          this.$refs['image' + component.id][0].getBoundingClientRect().width /
          this.renderedDimensions;
        console.log(component.size);
      }
      component.isDraggable = false;

      //change side bugs when mouse goes to fast throught page and canvas where moving function does not run
      if (component.templateSide === 'front' && component.y > 100) {
        if (this.backSide) {
          component.templateSide = 'back';
        } else {
          this.deleteComponent(component);
        }
      } else if (component.templateSide === 'back' && component.y < 0) {
        component.templateSide = 'front';
      }

      if (
        component.x > 100 ||
        component.x < 0 ||
        component.y > 100 ||
        component.y < 0
      ) {
        this.deleteComponent(component);
      }
    },
    background() {
      return this.side === 'front'
        ? this.$store.getters.template.backgroundFront
        : this.$store.getters.template.backgroundBack;
    },
    moveActiveComponent(event) {
      /* should use filter instead? */

      var component;
      if (this.activeComponent.isDraggable) {
        component =
          this.template.components[
            this.template.components.findIndex(
              (component) => component.id === this.activeComponent.id
            )
          ];
        if (
          component &&
          component.type !== 'qrcode' &&
          component.templateSide === this.side
        ) {
          // set component position x and y coordinates
          component.x =
            Math.round(
              (100 *
                (event.clientX -
                  this.$refs.page.getBoundingClientRect().x -
                  this.xDistanceMouseToComponentCenter)) /
                this.$refs.page.getBoundingClientRect().width
            ) < 0
              ? 0
              : Math.round(
                  (100 *
                    (event.clientX -
                      this.$refs.page.getBoundingClientRect().x -
                      this.xDistanceMouseToComponentCenter)) /
                    this.$refs.page.getBoundingClientRect().width
                ) > 100
              ? 100
              : Math.round(
                  (100 *
                    (event.clientX -
                      this.$refs.page.getBoundingClientRect().x -
                      this.xDistanceMouseToComponentCenter)) /
                    this.$refs.page.getBoundingClientRect().width
                );

          component.y =
            Math.round(
              (100 *
                (event.clientY -
                  this.$refs.page.getBoundingClientRect().y -
                  this.yDistanceMouseToComponentCenter)) /
                this.$refs.page.getBoundingClientRect().height
            ) < 0
              ? 0
              : Math.round(
                  (100 *
                    (event.clientY -
                      this.$refs.page.getBoundingClientRect().y -
                      this.yDistanceMouseToComponentCenter)) /
                    this.$refs.page.getBoundingClientRect().height
                ) > 100
              ? 100
              : Math.round(
                  (100 *
                    (event.clientY -
                      this.$refs.page.getBoundingClientRect().y -
                      this.yDistanceMouseToComponentCenter)) /
                    this.$refs.page.getBoundingClientRect().height
                );
        }
      }

      // Logic to lock components inside page (untested)
      //             if (component) {
      //               let componentWidth =  this.$refs[component.id][0].getBoundingClientRect().width
      //             // let componentHeight = this.$refs[component.id][0].getBoundingClientRect().height
      //             // let posX = Math.round( 100 * (event.clientX - this.$refs.page.getBoundingClientRect().x) / componentWidth)
      //             if(component.x >= 0 && component.x <= 100){
      //               component.x =  Math.round( 100 * (event.clientX - this.$refs.page.getBoundingClientRect().x) / this.$refs.page.getBoundingClientRect().width)
      //             }else if(component.x < 0){
      //                 component.x =  Math.round( 100 * (event.clientX - this.$refs.page.getBoundingClientRect().x) / this.$refs.page.getBoundingClientRect().width)
      //             }else if(component.x >= 100){
      //               console.log(component);
      //                 component.x =  100 -  Math.round( componentWidth / this.$refs.page.getBoundingClientRect().width)
      //             }else{
      //                 component.x = 0
      //             }

      //             if(component.y >= 0 && component.y <= 100){
      //               // console.log(componentHeight);
      //                 component.y = 100 * (event.clientY - this.$refs.page.getBoundingClientRect().y ) / this.$refs.page.getBoundingClientRect().height
      //             }else if(component.x < 0){
      //                 component.y = 100 - this.$refs[component.id][0].getBoundingClientRect().height
      //             }else{
      //                 component.y = 0
      //             }
      // }
    },
    isActiveComponent(component) {
      return component.id === this.activeComponent.id;
    },
    setActiveComponent(event, component) {
      component.isEditable ? null : (component.isDraggable = true);

      /* Prevent current activeComponent from remaining editable after switching to the new one */
      if (
        this.activeComponent.isEditable &&
        !this.isActiveComponent(component)
      ) {
        this.activeComponent.isEditable = false;
      }

      this.xDistanceMouseToComponentCenter =
        event.clientX -
        this.$refs[component.id][0].getBoundingClientRect().x -
        this.$refs[component.id][0].getBoundingClientRect().width / 2;
      this.yDistanceMouseToComponentCenter =
        event.clientY -
        this.$refs[component.id][0].getBoundingClientRect().y -
        this.$refs[component.id][0].getBoundingClientRect().height / 2;
      this.$store.dispatch('setActiveComponent', component);

      switch (component.type) {
        case 'text':
          this.selectTab('Attributes');
          break;
        case 'image':
          this.selectTab('Images');
          break;
        case 'signature':
          this.selectTab('Signatures');
          break;
        default:
          break;
      }
    },
    editComponent(component) {
      component.isEditable = !component.isEditable;
      if (component.isEditable) {
        this.iconColorEdit = 'blue';
      } else {
        this.iconColorEdit = '';
      }
      this.components.isDraggable = false;
    },
    isText(component) {
      return component.type === 'text';
    },
    isImage(component) {
      return component.type === 'image';
    },
    isSignature(component) {
      return component.type === 'signature';
    },
    isQRCode(component) {
      return component.type === 'qrcode';
    },
    deleteComponent(component) {
      const newComponents = this.template.components.filter(
        (e) => e.id !== component.id
      );

      this.$store.commit('setComponents', newComponents);

      const hasDynamicAttributes =
        newComponents.filter((e) => {
          return this.hasDynamicText(e);
        }).length > 0;

      this.$store.dispatch('setHasDynamicAttributes', hasDynamicAttributes);

      this.iconColorDelete = '';
      this.displayDeleteHover = false;
      this.$store.dispatch('setActiveComponent', '');
    },
    hasDynamicText(attribute) {
      if (!attribute?.text) return false;
      return attribute.text.match(/\[[A-Z0-9 ]*\]/g) ? true : false;
    },
    resizeTextArea(component, justification) {
      if (component.type === 'text') {
        var initialWidth = parseInt(
          this.$refs['textarea' + component.id][0].getBoundingClientRect().width
        );

        console.log(initialWidth);

        if (isNaN(component.previousWidth)) {
          component.previousWidth = initialWidth;
          console.log('isNaN.previousWidth', component.previousWidth);
        }
        console.log('previousWidth', component.previousWidth);

        if (justification) {
          var delta =
            (100 * (initialWidth - component.previousWidth)) /
            (2 * this.renderedDimensions * Math.sqrt(2));
          console.log('delta', delta, this.renderedDimensions);

          if (component.justification === 'left') {
            component.x = component.x + delta;
          } else if (component.justification === 'right') {
            component.x = component.x - delta;
          }
        }

        component.previousWidth = initialWidth;
      }
    },
    reactiveFontSize(fontSize) {
      return (this.renderedDimensions * fontSize) / 500;
    },
    selectTab(tab) {
      this.$store.dispatch('selectTab', tab);
    },
    keyboardActiveComponent(value) {
      if (this.activeComponent) {
        switch (value) {
          case 'up':
            this.activeComponent.y -= 0.1;
            console.log('upkeypressed');
            break;
          case 'down':
            this.activeComponent.y += 0.1;
            break;
          case 'left':
            if (!this.activeComponent.isEditable) {
              this.activeComponent.x -= 0.1;
            }
            break;
          case 'right':
            if (!this.activeComponent.isEditable) {
              this.activeComponent.x += 0.1;
            }
            break;
          case 'delete':
            if (!this.activeComponent.isEditable) {
              this.deleteComponent(this.activeComponent);
            }
            break;
          default:
            break;
        }
      }
    },
  },
  computed: {
    calcWidthPage() {
      return (
        Math.floor(this.pageHeight * layoutRatios[this.currentLayout]) + 'px'
      );
    },
    template() {
      return this.$store.getters.template;
    },
    activeComponent() {
      return this.$store.getters.activeComponent;
    },
    activeComponentWarp() {
      return this.$store.getters.activeComponent.warp;
    },
    components() {
      var templateSide = this.side;
      function sortComponentByTemplateSide(component) {
        return component.templateSide === templateSide;
      }
      return this.template.components.filter(sortComponentByTemplateSide);
    },
    renderedDimensions() {
      return this.$store.getters.renderedDimensions;
    },

    pageHeight() {
      return this.width; // - (16 * (4 + 3 + 3.5 + (3 * 5)))
    },

    backSide() {
      return this.$store.getters.backSide;
    },
    currentLayout() {
      return this.$store.getters.currentLayout;
    },
    layoutBackground() {
      return this.$store.getters.layoutBackgroundColor;
    },
  },
  watch: {
    activeComponent: function (newVal, oldVal) {
      this.iconColorEdit = '';

      if (oldVal.text === '') {
        this.deleteComponent(oldVal);
      }
    },
  },

  mounted() {
    for (var i = 0; i < this.components.length; i++) {
      this.resizeTextArea(this.components[i], true);
    }
  },
  updated() {
    for (var i = 0; i < this.components.length; i++) {
      this.resizeTextArea(this.components[i], true);
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Covered+By+Your+Grace&family=Dawning+of+a+New+Day&family=Nothing+You+Could+Do&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'Pixeled';
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url('../../assets/Fonts/Pixeled.ttf');
}

@font-face {
  font-family: 'PressStart2P';
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url('../../assets/Fonts/PressStart2P.ttf');
}

@font-face {
  font-family: 'Retro Gaming';
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url('../../assets/Fonts/Retro Gaming.ttf');
}

.page {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible !important;
  background-color: none;
  border: 1px solid #dadada;
}

.component {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  overflow: visible !important;
  cursor: default;
  min-width: 3.8rem;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}

.activeComponent {
  border: 1px dashed #009fb1;
}

.component-options {
  display: flex;
  justify-content: flex-end;
  min-width: 3.5rem;
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-100%, -150%);
  overflow: visible !important;
}

.component-coordinates {
  font-size: 11px;
  font-weight: 600;
  display: flex;
  justify-content: right;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, 25%);
}
</style>
