<template>
  <div class="canvas">
    <div v-if="editable" class="options">
      <Toolbar />
    </div>
    <div
      ref="passepartout"
      class="passepartout"
      @scroll="setCurrentSide()"
      @mousedown="clearActiveComponent()"
    >
      <div
        ref="page"
        id="page"
        class="page"
        :style="!editable ? 'pointer-events: none; user-select: none; ' : ''"
      >
        <Page :side="'front'" :width="width" :editable="editable" />
        <div
          v-if="editable && currentLayout"
          class="delete-page"
          style="
            display: flex;
            overflow: visible !important;
            white-space: nowrap;
            transform: translateX(125%);
          "
          @click="clearPage()"
          @mouseover="changeColorFront('red'), (displayClearHover = true)"
          @mouseout="changeColorFront(''), (displayClearHover = false)"
        >
          <IconDelete :color="this.iconColorFront" />
          <Hover v-show="displayClearHover">
            {{ $t('canvas.clearPage') }}
          </Hover>
        </div>
      </div>

      <div
        v-show="!backSide && editable && currentLayout === 'A4'"
        class="add-page"
        @click="enableBackSide()"
        :style="{
          minWidth: this.renderedDimensions * Math.sqrt(2) + 'px',
          color: '#373c43',
        }"
      >
        <IconAddPage style="margin-right: 0.5rem" />{{
          $t('canvas.addBackside')
        }}
      </div>

      <div
        ref="back"
        id="back"
        v-show="backSide"
        style="margin-top: 1.25rem; margin-bottom: 5rem"
        class="page"
        :style="!editable ? 'pointer-events: none; user-select: none; ' : ''"
      >
        <Page :side="'back'" :width="width" :editable="editable" />
        <div
          v-if="editable"
          class="delete-page"
          @click="deletePage()"
          style="
            display: flex;
            overflow: visible !important;
            white-space: nowrap;
            z-index: 1;
          "
          :style="{
            transform: 'translateX(125%)',
          }"
          @mouseover="changeColorBack('red'), (displayDeleteHover = true)"
          @mouseout="changeColorBack(''), (displayDeleteHover = false)"
        >
          <IconDelete :color="this.iconColorBack" />
          <Hover v-show="displayDeleteHover">
            {{ $t('canvas.deletePage') }}
          </Hover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconAddPage from '../../assets/icon-add-page';
import IconDelete from '../../assets/icon-delete-attribute';
import Page from '../Canvas/Page.vue';
import Toolbar from '../Canvas/Toolbar.vue';
import Hover from '../Misc/Hover.vue';

export default {
  data() {
    return {
      /* 16 - convertion rem to px; heights: 4 - header, 3 - canvas toolbar, 3.5 - footer, 3 * 5 - passepartout padding and add-page height and margin, 2 - border corrections and ???*/
      pageHeight: screen.availHeight - 16 * (4 + 3 + 3.5 + 3 * 5),
      iconColorFront: '',
      iconColorBack: '',
      displayDeleteHover: false,
      displayClearHover: false,
    };
  },
  props: {
    editable: {
      default: true,
    },
    width: {
      default: screen.availHeight - 16 * (4 + 3 + 3.5 + 3 * 5),
    },
  },
  components: {
    Toolbar,
    IconAddPage,
    IconDelete,
    Page,
    Hover,
  },
  methods: {
    clearActiveComponent() {
      if (this.activeComponent.isEditable) {
        this.activeComponent.isEditable = false;
      }
      this.$store.dispatch('setActiveComponent', '');
    },
    enableBackSide() {
      this.$store.dispatch('enableBackSide', true);
    },
    clearPage() {
      this.template.idFront = 0;
      this.template.backgroundFront = '';
      /* how to splice only frontside components and avoid qrcode */
      function isBack(component) {
        return component.templateSide === 'back';
      }
      if (this.template.components) {
        this.template.components = this.template.components.filter(isBack);
      }

      if (this.template.components.length == 0) {
        this.$store.dispatch('setHasDynamicAttributes', false);
      }

      this.template.components.push({
        id: 0,
        type: 'qrcode',
        templateSide: 'front',
        x: 94.4,
        y: 92.4,
        isDraggable: false,
      });
      this.template.backgroundFront = '';
      this.$store.dispatch('setActiveComponent', '');
    },
    deletePage() {
      this.template.idBack = 0;
      this.template.backgroundBack = '';
      /* how to splice only backside components */
      function isFront(component) {
        return component.templateSide === 'front';
      }

      if (this.template.components) {
        this.$store.commit(
          'setComponents',
          this.template.components.filter(isFront)
        );
      }
      if (this.template.components.length == 0) {
        this.$store.dispatch('setHasDynamicAttributes', false);
      }
      this.template.backgroundBack = '';
      this.$store.dispatch('setActiveComponent', '');
      this.$store.dispatch('enableBackSide', false);
    },
    /* these changecolor methods are not requires and iconColor data can be directly changed in the DOM */
    changeColorFront(color) {
      return (this.iconColorFront = color);
    },
    changeColorBack(color) {
      return (this.iconColorBack = color);
    },
    setCurrentSide() {
      const scrollHeight = this.$refs.passepartout.scrollHeight;
      const currentHeight =
        this.$refs.passepartout.scrollTop +
        this.$refs.passepartout.offsetHeight;
      if (this.backSide) {
        if (currentHeight < (scrollHeight * 3) / 4) {
          this.$store.dispatch('setCurrentSide', 'front');
        } else {
          this.$store.dispatch('setCurrentSide', 'back');
        }
      } else {
        this.$store.dispatch('setCurrentSide', 'front');
      }
      /* useful for UX calibration */
      console.log(this.currentSide);
    },
    selectTab(tab) {
      this.$store.dispatch('selectTab', tab);
    },
  },
  computed: {
    backSide() {
      return this.$store.getters.backSide;
    },
    currentSide() {
      return this.$store.getters.currentSide;
    },
    renderedDimensions() {
      return this.$store.getters.renderedDimensions;
    },
    template() {
      return this.$store.getters.template;
    },
    components() {
      return this.$store.getters.template.components;
    },
    activeComponent() {
      return this.$store.getters.activeComponent;
    },
    currentLayout() {
      return this.$store.getters.currentLayout;
    },
    layoutBackgroundColor: {
      get() {
        return this.$store.getters.layoutBackgroundColor;
      },
      set(value) {
        this.$store.commit('updateLayoutBackgroundColor', value);
      },
    },
  },
  mounted() {
    this.$store.dispatch(
      'setRenderedDimensions',
      this.$refs.page.getBoundingClientRect().height
    );
  },
  watch: {
    backSide: function (newBackSide) {
      if (newBackSide === true) {
        /* Not an ideal solution to scroll, but it forces the code to run after render */
        setTimeout(() => {
          this.$refs.back.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    },
    components: function (value) {
      console.log('value changed', value);
    },
    currentLayout: function () {
      this.$store.dispatch(
        'setRenderedDimensions',
        this.$refs.page.getBoundingClientRect().height
      );
    },
  },
};
</script>

<style scoped>
.canvas {
  display: flex;
  min-width: 7rem;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.options {
  z-index: 8;
  position: sticky;
  top: 0;
  display: flex;
  min-height: 3rem;
  max-height: 3rem;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  overflow: visible !important;
}

.undo-redo {
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem;
  min-width: 6rem;
}

.passepartout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding-top: 5rem;
  background-color: #e2e2e2;
  border-left: 1px solid #e2e2e2;
  overflow-y: scroll !important;
}

.passepartout::-webkit-scrollbar {
  display: contents;
  height: 0.5rem;
}

.passepartout::-webkit-scrollbar-thumb {
  background-color: #b8b9bb;
}

.page {
  display: flex;
  position: relative;
  overflow: visible !important;
}

.delete-page {
  display: flex;
  position: absolute;
  top: 0%;
  right: 0%;
}

.add-page {
  display: flex;
  min-height: 2.5rem;
  max-height: 2.5rem;
  align-items: center;
  justify-content: center;
  margin: 1.25rem 0;
  font-weight: 500;
  font-size: 15px;
  background-color: #eeeeee;
  color: #b8b9bb; /* Maybe in default charcoal color since it is an important button */
  cursor: pointer;
}
</style>
