<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="10"
    viewBox="0 0 14 10"
  >
    <g fill="none" fill-rule="evenodd" stroke-linecap="square">
      <g :stroke="iconColor()">
        <g>
          <g>
            <g>
              <path
                d="M.5.5h13M.5 6.5h13M.5 3.5h9M.5 9.5h9"
                transform="translate(-1016 -125) translate(-9574 114) translate(0 11) translate(10590)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? '#009fb1' : '#373c43';
    },
  },
};
</script>
