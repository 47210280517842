var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{staticClass:"modal-backdrop"},[_c('div',{staticClass:"modal-wallid message",attrs:{"role":"dialog","aria-labelledby":"modalTitle","aria-describedby":"modalDescription"},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.message != 'storing')?_c('header',{staticClass:"modal-header",class:_vm.message == 'confirm' ? '--text-header' : '',attrs:{"id":"modalTitle"}},[_vm._t("header",function(){return [(_vm.message == 'confirm')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('modals.' + _vm.message + '.title'))+" ")]):_vm._e(),_c('v-spacer'),_c('button',{staticClass:"btn-close",attrs:{"type":"button","aria-label":"Close modal"},on:{"click":_vm.close}},[_c('IconClose')],1)]})],2):_vm._e(),_c('div',{staticClass:"modal-body text-center pa-7"},[_vm._t("body",function(){return [(_vm.message == 'storing')?_c('Spinner'):_vm._e(),(
              _vm.message == 'credentials' ||
              _vm.message == 'credential' ||
              _vm.message == 'email' ||
              _vm.message == 'wallet' ||
              _vm.message == 'successCreateTemplate'
            )?_c('IconSuccess'):_vm._e(),(_vm.message != 'confirm')?_c('h2',{staticClass:"subtitle_header mx-auto pa-4"},[_vm._v(" "+_vm._s(_vm.$t('modals.' + _vm.message + '.title'))+" ")]):_vm._e(),_c('p',{staticClass:"subtitle_text px-16"},[_vm._v(" "+_vm._s(_vm.$t('modals.' + _vm.message + '.text'))+" ")])]})],2),(_vm.message == 'confirm')?_c('div',{staticClass:"modal-footer"},[_vm._t("footer",function(){return [_c('v-row',{staticClass:"justify-end",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"back mr-4",on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('modals.' + _vm.message + '.cancel')))]),_c('v-btn',{staticClass:"next",attrs:{"loading":_vm.disable,"disabled":_vm.disable},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" "+_vm._s(_vm.$t('modals.' + _vm.message + '.confirm'))+" ")])],1)]})],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }