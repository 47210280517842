var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"page",staticClass:"page",style:({
    minWidth: _vm.calcWidthPage,
    maxWidth: _vm.calcWidthPage,

    minHeight: this.pageHeight + 'px',
    maxHeight: this.pageHeight + 'px',
    backgroundColor: _vm.layoutBackground,
    backgroundImage: 'url(' + _vm.background() + ')',
    backgroundSize: _vm.currentLayout == 'Card' ? 'contain' : 'cover',
    backgroundPosition: 'center center',
    borderRadius: _vm.currentLayout === 'Badge' ? '50%' : '0%',
    visibility: _vm.currentLayout ? 'visible' : 'hidden',
  }),attrs:{"tabindex":"0"},on:{"mousemove":_vm.moveActiveComponent,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.keyboardActiveComponent('up')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.keyboardActiveComponent('down')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.keyboardActiveComponent('left')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return _vm.keyboardActiveComponent('right')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.keyboardActiveComponent('delete')}]}},_vm._l((_vm.components),function(component,index){return _c('div',{key:component.id,ref:component.id,refInFor:true,staticClass:"component",class:{ activeComponent: _vm.isActiveComponent(component) },style:({
      top: component.y + '%',
      left: component.x + '%',
      padding: '0.5rem',
      minHeight: _vm.componentHeight(component),
      minWidth: _vm.componentWidth(component),
      zIndex: _vm.isQRCode(component) ? _vm.components.length : index,
    }),on:{"mousedown":function($event){$event.stopPropagation();return _vm.setActiveComponent($event, component)},"mouseup":function($event){return _vm.updateComponent(component)},"dblclick":function($event){(component.isEditable = true), (_vm.iconColorEdit = 'blue')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActiveComponent(component)),expression:"isActiveComponent(component)"}],staticClass:"component-options",on:{"mousedown":function($event){return $event.stopPropagation()}}},[(!_vm.isQRCode(component))?_c('div',{staticStyle:{"display":"flex","overflow":"visible !important","z-index":"12"},on:{"click":function($event){return _vm.deleteComponent(component)},"mouseover":function($event){_vm.iconColorDelete = 'blue'},"mouseout":function($event){_vm.iconColorDelete = ''}}},[_c('div',{staticStyle:{"display":"flex","position":"relative","overflow":"visible !important"},on:{"mouseover":function($event){_vm.displayDeleteHover = true},"mouseout":function($event){_vm.displayDeleteHover = false}}},[_c('IconDelete',{attrs:{"color":_vm.iconColorDelete}}),_c('Hover',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayDeleteHover),expression:"displayDeleteHover"}]},[_vm._v(" "+_vm._s(_vm.$t('page.delete'))+" ")])],1)]):_vm._e(),(!_vm.isQRCode(component))?_c('div',{staticStyle:{"display":"flex","overflow":"visible !important","z-index":"12"},on:{"click":function($event){return _vm.editComponent(component)},"mouseover":function($event){_vm.iconColorEdit = 'blue'},"mouseout":function($event){_vm.iconColorEdit = component.isEditable
            ? (_vm.iconColorEdit = 'blue')
            : (_vm.iconColorEdit = '')}}},[_c('div',{staticStyle:{"display":"flex","position":"relative","overflow":"visible !important","margin-left":"0.5rem"},on:{"mouseover":function($event){_vm.displayEditHover = true},"mouseout":function($event){_vm.displayEditHover = false}}},[_c('IconEdit',{attrs:{"color":_vm.iconColorEdit}}),_c('Hover',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayEditHover),expression:"displayEditHover"}]},[_vm._v(" "+_vm._s(_vm.$t('page.edit'))+" ")])],1)]):_vm._e(),(_vm.isQRCode(component))?_c('div',{staticStyle:{"display":"flex","overflow":"visible !important","z-index":"15"}},[_c('div',{staticStyle:{"display":"flex","position":"relative","overflow":"visible !important"},on:{"mouseover":function($event){_vm.displayQRCodeHover = true},"mouseout":function($event){_vm.displayQRCodeHover = false}}},[_c('IconInfo'),_c('Hover',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayQRCodeHover),expression:"displayQRCodeHover"}]},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('page.qrcode'))}})])],1)]):_vm._e()]),(
        _vm.isText(component) &&
        _vm.editable &&
        (component.warp == 0 || component.isEditable)
      )?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(component.text),expression:"component.text"}],key:'textarea' + component.id,ref:'textarea' + component.id,refInFor:true,style:({
        resize: 'none',
        whiteSpace: 'pre',
        fontFamily: component.fontFamily,
        fontSize: _vm.reactiveFontSize(component.fontSize) + 'px',
        fontStyle: component.isItalic ? 'italic' : 'normal',
        fontWeight: component.isBold ? 'bold' : 'normal',
        textDecoration: component.isUnderlined ? 'underline' : 'none',
        textAlign: component.justification,
        color: component.fontColor,
        opacity: component.visible ? '1' : '0',
        overflow: 'visible',
        width: 'auto',
      }),attrs:{"disabled":!component.isEditable,"rows":"1"},domProps:{"value":(component.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(component, "text", $event.target.value)},function($event){return _vm.resizeTextArea(component, true)}]}}):(
        _vm.isText(component) &&
        !isNaN(component.warp) &&
        component.warp != 0 &&
        !component.isEditable
      )?_c('div',{ref:'textarea' + component.id,refInFor:true,staticStyle:{"overflow":"visible !important"},style:({
        visibility:
          component.warp != 0 && !component.isEditable ? 'visible' : 'hidden',
      })},[_c('svg',{ref:'curvedtext' + component.id + '0',refInFor:true,staticStyle:{"position":"absolute","overflow":"visible !important","top":"50%","left":"50%","transform":"translate(-50%, -50%)"},attrs:{"viewBox":"0 0 100 100"}},[_c('path',{attrs:{"id":"curve","fill":"transparent","stroke":_vm.isActiveComponent(component) ? '#e1e1e1' : 'transparent',"stroke-width":"3px","stroke-dasharray":"10%","d":component.warp > 0
              ? 'M 50, ' +
                (50 + Math.abs(component.warp)) +
                'a ' +
                Math.abs(component.warp) +
                ',' +
                Math.abs(component.warp) +
                ' 0 1,1 0,' +
                Math.abs(component.warp) * -2 +
                'a ' +
                Math.abs(component.warp) +
                ',' +
                Math.abs(component.warp) +
                ' 0 1,1 0, ' +
                Math.abs(component.warp) * 2
              : 'M 50, ' +
                (50 - Math.abs(component.warp)) +
                'a ' +
                Math.abs(component.warp) +
                ',' +
                Math.abs(component.warp) +
                ' 0 1,0 0,' +
                Math.abs(component.warp) * 2 +
                'a ' +
                Math.abs(component.warp) +
                ',' +
                Math.abs(component.warp) +
                ' 0 1,0 0, ' +
                Math.abs(component.warp) * -2}}),_c('text',{staticClass:"curved-text",style:({
            fontFamily: component.fontFamily + ' !important',
            fontSize: _vm.reactiveFontSize(component.fontSize) * 3.5 + 'px',
            fontStyle: component.isItalic ? 'italic' : 'normal',
            fontWeight: component.isBold ? 'bold' : 'normal',
            textDecoration: component.isUnderlined ? 'underline' : 'none',
            fill: component.fontColor,
            letterSpacing: component.spacing + 'px',
          }),attrs:{"dy":component.warp > 0 ? '-10%' : '110%',"width":"100"}},[_c('textPath',{style:({
              fontFamily: component.fontFamily + ' !important',
            }),attrs:{"text-anchor":"middle","startOffset":"50%","xlink:href":"#curve"}},[_vm._v(" "+_vm._s(component.text)+" ")])])])]):(_vm.isText(component))?_c('div',{key:'textarea' + component.id + 'text',ref:'textarea' + component.id,refInFor:true,style:({
        resize: 'none',
        whiteSpace: 'pre',
        fontFamily: component.fontFamily,
        fontSize: _vm.reactiveFontSize(component.fontSize) + 'px',
        fontStyle: component.isItalic ? 'italic' : 'normal',
        fontWeight: component.isBold ? 'bold' : 'normal',
        textDecoration: component.isUnderlined ? 'underline' : 'none',
        textAlign: component.justification,
        color: component.fontColor,
        opacity: 'visible' in component && !component.visible ? '0' : '1',
        margin: '0.5rem',
      }),attrs:{"disabled":!component.isEditable,"tabindex":"-1","cols":"1","rows":"1"},on:{"input":function($event){return _vm.resizeTextArea(component, true)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(component.text),expression:"component.text"}],staticStyle:{"display":"none"},domProps:{"value":(component.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(component, "text", $event.target.value)},function($event){return _vm.resizeTextArea(component, true)}]}}),_vm._v(" "+_vm._s(component.text)+" ")]):_vm._e(),(_vm.isImage(component))?_c('div',{ref:'image' + component.id,refInFor:true,style:({
        width: _vm.renderedDimensions * component.size + 'px',
        resize:
          component.isEditable && component.type === 'image'
            ? 'horizontal'
            : 'none',
      })},[_c('img',{staticStyle:{"pointer-events":"none"},style:({ width: '100%' }),attrs:{"src":component.src,"alt":""}})]):_vm._e(),(_vm.isSignature(component) && !component.src)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(component.text),expression:"component.text"}],key:'textarea' + component.id,ref:'input' + component.id,refInFor:true,style:({
        whiteSpace: 'pre',
        fontSize: _vm.reactiveFontSize(component.fontSize) + 'px',
        fontFamily: component.fontFamily + '!important',
        color: component.fontColor,
      }),attrs:{"type":"text","disabled":!component.isEditable,"size":"1"},domProps:{"value":(component.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(component, "text", $event.target.value)},function($event){return _vm.resizeTextArea(component, false)}]}}):_vm._e(),(_vm.isSignature(component) && component.src)?_c('div',{ref:'image' + component.id,refInFor:true,style:({
        width: _vm.renderedDimensions * component.size + 'px',
        resize:
          component.isEditable && component.type === 'signature'
            ? 'horizontal'
            : 'none',
      })},[_c('img',{staticStyle:{"pointer-events":"none"},style:({ width: '100%' }),attrs:{"src":component.src,"alt":""}})]):_vm._e(),(_vm.isQRCode(component))?_c('div',{staticClass:"qrcode",staticStyle:{"user-select":"none","display":"flex","flex-direction":"column","align-items":"center","padding":"0.5rem"}},[_c('div',{staticStyle:{"margin-bottom":"0.3rem"},style:({ fontSize: _vm.renderedDimensions * 0.0125 + 'px' })},[_vm._v(" "+_vm._s(_vm.$t('page.qrcodePalceholder'))+" ")]),_c('img',{staticStyle:{"pointer-events":"none","user-select":"none"},style:({ width: _vm.renderedDimensions * 0.1 + 'px' }),attrs:{"src":require("../../assets/qr-code.png"),"alt":""}})]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }