<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="10"
    viewBox="0 0 14 10"
  >
    <g fill="none" fill-rule="evenodd">
      <g :stroke="iconColor()">
        <g>
          <g>
            <path
              fill="#D8D8D8"
              d="M0.5 0.5H9.5V1.5H0.5z"
              transform="translate(-1080 -125) translate(-9574 114) translate(10654 11) translate(4 9)"
            />
            <g stroke-linecap="square">
              <path
                d="M.5.5h13M.5 6.5h13M13.5 9.5h-9M13.5 3.5h-9"
                transform="translate(-1080 -125) translate(-9574 114) translate(10654 11)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? '#009fb1' : '#373c43';
    },
  },
};
</script>
