<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="9"
    viewBox="0 0 7 9"
  >
    <g fill="none" fill-rule="evenodd">
      <g :fill="iconColor()" fill-rule="nonzero">
        <g>
          <g>
            <path
              d="M6.127 0l-.095.419c-.131.004-.303.023-.514.057-.211.034-.368.065-.47.095-.177.055-.304.14-.38.254-.076.114-.131.238-.165.374L2.981 7.776c-.008.03-.015.063-.02.101-.003.038-.005.072-.005.102 0 .097.02.18.063.247.042.068.118.127.228.178.064.03.203.064.419.104.216.04.376.065.482.073L4.053 9H0l.095-.419c.119-.008.288-.02.508-.038.22-.017.376-.042.469-.076.165-.059.29-.14.374-.244.085-.104.142-.23.171-.377l1.516-6.584c.009-.038.015-.076.02-.114.003-.038.006-.076.006-.114 0-.085-.018-.159-.054-.222-.036-.064-.111-.12-.226-.171C2.765.59 2.61.542 2.413.498c-.196-.045-.341-.07-.434-.08L2.074 0h4.053z"
              transform="translate(-946 -125) translate(502 114) translate(444 11)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? '#009fb1' : '#373c43';
    },
  },
};
</script>
