<template>
  <svg
    :fill="iconColor()"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 18 16"
    style="enable-background: new 0 0 18 16"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M16.5,14c-4.1-4.1-10.9-4.1-15,0l-0.6-0.6c4.5-4.5,11.7-4.5,16.2,0L16.5,14z"
      />
      <path
        class="st1"
        d="M7.4,6.8L3.8,7.6L3.4,9.5l-1,0.2l1.5-7.5l1-0.2l4.7,6.1l-1,0.2L7.4,6.8z M6.9,6.1l-2.2-3L3.9,6.8L6.9,6.1z"
      />
      <path
        class="st1"
        d="M15.8,4.8C16,5.4,16,6,15.8,6.5l-1.1,3l-0.9-0.3l0.2-0.7c-0.2,0.2-0.5,0.3-0.8,0.3c-0.4,0-0.7,0-1.1-0.2
		c-0.5-0.2-0.9-0.5-1.2-0.9c-0.3-0.4-0.3-0.9-0.1-1.3c0.1-0.4,0.5-0.7,0.9-0.9c0.6-0.1,1.1-0.1,1.7,0.2l1.4,0.5l0.1-0.2
		c0.1-0.3,0.1-0.7,0-1c-0.2-0.3-0.5-0.5-0.9-0.6c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6,0-0.9,0.1l-0.1-0.8c0.4-0.1,0.7-0.2,1.1-0.1
		c0.4,0,0.8,0.1,1.2,0.2C15,3.9,15.5,4.3,15.8,4.8z M13.6,8.1c0.3-0.1,0.6-0.2,0.8-0.4L14.6,7l-1.4-0.5c-0.8-0.3-1.2-0.2-1.4,0.3
		c-0.1,0.2-0.1,0.5,0.1,0.7C12,7.8,12.3,8,12.6,8.1C12.9,8.2,13.2,8.2,13.6,8.1z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? '#009fb1' : '#373c43';
    },
  },
};
</script>
