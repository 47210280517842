<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g
            transform="translate(-809 -450) translate(593 413) translate(216 37)"
          >
            <circle cx="12" cy="12" r="12" :fill="iconColor()" />
            <g fill="#FFF" fill-rule="nonzero">
              <g>
                <path
                  d="M9.393.552c.735-.736 1.933-.736 2.69 0l1.365 1.365c.736.736.736 1.934 0 2.69l-8.846 8.847c-.357.357-.84.546-1.345.546H.63c-.357 0-.63-.273-.63-.63v-2.627c0-.504.21-.988.546-1.345L9.393.552zm1.71.918c-.263-.263-.68-.263-.943 0L8.91 2.721l2.369 2.37 1.25-1.25c.264-.264.264-.681 0-.944L11.104 1.47zm-.921 4.545L7.99 3.818l-6.535 6.55c-.121.122-.182.264-.182.427v1.932H3.2c.162 0 .324-.06.426-.183l6.555-6.53z"
                  transform="translate(5 5)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['color'],
  methods: {
    iconColor() {
      if (this.color === 'blue') {
        return '#009fb1';
      } else {
        return '#B8B9BB';
      }
    },
  },
};
</script>
