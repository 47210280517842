<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="11"
    viewBox="0 0 10 11"
  >
    <g fill="none" fill-rule="evenodd">
      <g :fill="iconColor()" fill-rule="nonzero">
        <g>
          <g>
            <path
              d="M4.456 8.472c-1.088 0-1.936-.312-2.544-.936C1.304 6.912 1 6.004 1 4.812V0h.888v4.776c0 .976.22 1.704.66 2.184.44.48 1.076.72 1.908.72.84 0 1.48-.24 1.92-.72.44-.48.66-1.208.66-2.184V0H7.9v4.812c0 1.192-.302 2.1-.906 2.724-.604.624-1.45.936-2.538.936zM0 10.897L0 10.224 9.228 10.224 9.228 10.897z"
              transform="translate(-976 -125) translate(502 114) translate(474 11)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? '#009fb1' : '#373c43';
    },
  },
};
</script>
