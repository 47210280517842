<template>
  <svg width="18" height="16" xmlns="http://www.w3.org/2000/svg">
    <g :fill="iconColor()" fill-rule="evenodd">
      <path
        d="M6.022 1 3 8h.98l.736-1.75h3.548L8.998 8H10L6.967 1h-.945zm.468 1.02 1.44 3.43H5.05l1.44-3.43zM13.663 7.09c.232-.151.4-.365.504-.642v-.685h-1.284c-.706 0-1.06.253-1.06.758 0 .246.09.441.27.583.18.142.428.213.746.213.319 0 .593-.076.824-.227m.829-3.591c.338.332.508.829.508 1.488v2.958h-.79v-.647c-.14.228-.337.403-.594.522-.258.12-.562.18-.916.18-.514 0-.927-.131-1.236-.397-.31-.265-.464-.613-.464-1.044 0-.432.148-.779.443-1.04.295-.263.763-.393 1.405-.393h1.32v-.177c0-.38-.105-.674-.314-.878-.209-.203-.514-.304-.92-.304a2.29 2.29 0 0 0-.798.143c-.26.095-.48.224-.66.383l-.346-.666c.237-.202.521-.358.85-.466.33-.108.68-.161 1.05-.161.636 0 1.124.166 1.462.499M13.158 9.643l2.078 1.929H2.765l2.079-1.929L4.234 9 1 12l3.234 3 .61-.643-2.08-1.929h12.472l-2.078 1.929.61.643L17 12l-3.232-3z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? '#009fb1' : '#373c43';
    },
  },
};
</script>
