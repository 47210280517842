<template>
  <div class="toolbar">
    <!--         <div class="undo">
            <IconUndo/>
        </div>
        <div class="redo">
            <IconRedo/>
        </div> -->
    <div class="edition">
      <span v-show="activeComponent" style="margin-right: 0.3rem"> X: </span>
      <div
        v-show="activeComponent"
        style="
          width: 3.5rem;
          margin-right: 0.5rem;
          border-radius: 0.3rem;
          justify-items: center;
        "
      >
        {{ (Math.floor(activeComponent.x * 10) / 10).toFixed(1) }}
      </div>
      <span v-show="activeComponent" style="margin-right: 0.3rem"> Y: </span>
      <div
        v-show="activeComponent"
        style="
          width: 3.5rem;
          margin-right: 8px;
          border-radius: 0.3rem;
          justify-items: center;
        "
      >
        {{ (Math.floor(activeComponent.y * 10) / 10).toFixed(1) }}
      </div>
      <div
        v-show="isText() || (isSignature() && !activeComponent.src)"
        class="picker"
      >
        <input ref="color" type="color" v-model="activeComponent.fontColor" />
      </div>
      <div
        v-show="isText()"
        style="
          border-radius: 0.3rem;
          margin-right: 8px;
          padding: 0 0.5rem;
          justify-items: center;
        "
      >
        <input type="checkbox" v-model="activeComponent.visible" />
      </div>
      <div
        v-show="selectedTab === 'Backgrounds' && !activeComponent"
        class="picker"
      >
        <input type="color" v-model="layoutBackgroundColor" />
      </div>
      <div v-show="isText()" class="font-family">
        <select v-model="activeComponent.fontFamily">
          <option value="Abril Fatface">Abril Fatface</option>
          <option value="Acme">Acme</option>
          <option value="Amatic SC">Amatic SC</option>
          <option value="Anonymous Pro">Anonymous Pro</option>
          <option value="Architects Daughter">Architects Daughter</option>
          <option value="Bad Script">Bad Script</option>
          <option value="Bangers">Bangers</option>
          <option value="Caveat">Caveat</option>
          <option value="Cinzel">Cinzel</option>
          <option value="Courgette">Courgette</option>
          <option value="Crimson Text">Crimson Text</option>
          <option value="Dancing Script">Dancing Script</option>
          <option value="Fredoka One">Fredoka One</option>
          <option value="Great Vibes">Great Vibes</option>
          <option value="Indie Flower">Indie Flower</option>
          <option value="Josefin Sans">Josefin Sans</option>
          <option value="Lato">Lato</option>
          <option value="Lobster">Lobster</option>
          <option value="Merienda">Merienda</option>
          <option value="Merriweather">Merriweather</option>
          <option value="Montserrat">Montserrat</option>
          <option value="Noto Sans JP">Open Sans</option>
          <option value="Nunito">Nunito</option>
          <option value="Open Sans">Open Sans</option>
          <option value="Oswald">Oswald</option>
          <option value="Pacifico">Pacifico</option>
          <option value="Patrick Hand">Patrick Hand</option>
          <option value="Permanent Marker">Permanent Marker</option>
          <option value="Pixeled">Pixeled</option>
          <option value="Playfair Display">Plafair Display</option>
          <option value="Poppins">Poppins</option>
          <option value="PressStart2P">PressStart2P</option>
          <option value="PT Sans">PT Sans</option>
          <option value="Raleway">Raleway</option>
          <option value="Retro Gaming">Retro Gaming</option>
          <option value="Roboto">Roboto</option>
          <option value="Roboto Condensed">Roboto Condensed</option>
          <option value="Roboto Mono">Roboto Mono</option>
          <option value="Roboto Slab">Roboto Slab</option>
          <option value="Rubik">Rubik</option>
          <option value="Shadows Into Light">Shadows Into Light</option>
          <option value="Source Sans">Source Sans</option>
          <option value="Tangerine">Tangerine</option>
          <option value="Ubuntu">Ubuntu</option>
        </select>
      </div>
      <div v-show="isSignature() && !activeComponent.src" class="font-family">
        <select v-model="activeComponent.fontFamily">
          <option
            value="Dawning of a New Day"
            style="font-family: Dawning of a New Day !important"
          >
            Dawning of a New Day
          </option>
          <option
            value="Cedarville Cursive"
            style="font-family: Cedarville Cursive !important"
          >
            Cedarville Cursive
          </option>
          <option
            value="Covered By Your Grace"
            style="font-family: Covered By Your Grace !important"
          >
            Covered By Your Grace
          </option>
          <option
            value="Nothing You Could Do"
            style="font-family: Nothing You Could Do !important"
          >
            Nothing You Could Do
          </option>
        </select>
      </div>
      <div
        v-show="isText() || (isSignature() && !activeComponent.src)"
        class="decrease-font"
        @click="activeComponent.fontSize -= 1"
      >
        <IconDecreaseFont />
      </div>
      <div
        v-show="isText() || (isSignature() && !activeComponent.src)"
        class="font-size"
      >
        <!-- default value is 2.5% of the page height, 1000* and - 9 makes it display 16 -->
        <!-- make it v-model to allow edition -->
        {{ activeComponent.fontSize }}
      </div>
      <div
        v-show="isText() || (isSignature() && !activeComponent.src)"
        class="increase-font"
        @click="activeComponent.fontSize++"
      >
        <IconIncreaseFont />
      </div>

      <div
        v-show="isText() && showFeature()"
        :class="{ active: spacingVisible }"
        style="
          width: 2rem;
          border-radius: 0.3rem;
          margin-right: 8px;
          justify-items: center;
          position: relative;
          overflow: visible !important;
        "
      >
        <div
          @click.prevent="spacingVisible = !spacingVisible"
          style="
            width: 2rem;
            max-height: 100%;
            padding: 0;
            margin: 0;
            border: none;
            background-color: transparent;
          "
        >
          <IconSpacing :selected="spacingVisible" />
        </div>
        <div
          v-if="spacingVisible"
          style="
            position: absolute;
            top: 130%;
            right: 0%;
            background-color: #ffffff;
            padding: 12px 16px;
            height: min-content;
          "
        >
          <span
            style="
              white-space: nowrap;
              font-size: 12px;
              font-weight: 500;
              margin-right: 24px;
            "
          >
            Letter Spacing
          </span>
          <div
            v-show="isText()"
            class="decrease-font"
            @click="activeComponent.spacing -= 1"
          >
            <IconDecreaseFont />
          </div>
          <div v-show="isText()" class="font-size">
            <!-- default value is 2.5% of the page height, 1000* and - 9 makes it display 16 -->
            <!-- make it v-model to allow edition -->
            {{ activeComponent.spacing }}
          </div>
          <div
            v-show="isText()"
            class="increase-font"
            style="margin-right: 0"
            @click="activeComponent.spacing++"
          >
            <IconIncreaseFont />
          </div>
        </div>
      </div>

      <div
        v-show="isText()"
        class="bold"
        :class="{
          active: activeComponent.isBold,
          noRightBorder: activeComponent.isItalic && !activeComponent.isBold,
        }"
        @click="activeComponent.isBold = !activeComponent.isBold"
      >
        <IconBold :selected="activeComponent.isBold" />
      </div>
      <div
        v-show="isText()"
        class="italic"
        :class="{
          active: activeComponent.isItalic,
          noRightBorder: activeComponent.isUnderlined,
          noLeftBorder: activeComponent.isBold,
        }"
        @click="activeComponent.isItalic = !activeComponent.isItalic"
      >
        <IconItalic :selected="activeComponent.isItalic" />
      </div>
      <div
        v-show="isText()"
        class="underlined"
        :class="{
          active: activeComponent.isUnderlined,
          noLeftBorder:
            activeComponent.isItalic && !activeComponent.isUnderlined,
        }"
        @click="activeComponent.isUnderlined = !activeComponent.isUnderlined"
      >
        <IconUnderlined :selected="activeComponent.isUnderlined" />
      </div>
      <div
        v-show="isText()"
        class="align-left"
        :class="{
          active: activeComponent.justification === 'left',
          noRightBorder: activeComponent.justification === 'center',
        }"
        @click="activeComponent.justification = 'left'"
      >
        <IconJustifyLeft :selected="activeComponent.justification === 'left'" />
      </div>
      <div
        v-show="isText()"
        class="align-center"
        :class="{ active: activeComponent.justification === 'center' }"
        @click="activeComponent.justification = 'center'"
      >
        <IconJustifyCenter
          :selected="activeComponent.justification === 'center'"
        />
      </div>
      <div
        v-show="isText()"
        class="align-right"
        :class="{
          active: activeComponent.justification === 'right',
          noLeftBorder: activeComponent.justification === 'center',
        }"
        @click="activeComponent.justification = 'right'"
      >
        <IconJustifyRight
          :selected="activeComponent.justification === 'right'"
        />
      </div>
      <div
        v-show="isText() && showFeature()"
        :class="{ active: warpingVisible }"
        style="
          width: 2rem;
          border-radius: 0.3rem;
          margin-right: 8px;
          justify-items: center;
          position: relative;
          overflow: visible !important;
        "
      >
        <div
          @click.prevent="warpingVisible = !warpingVisible"
          style="
            width: 2rem;
            max-height: 100%;
            padding: 0;
            margin: 0;
            border: none;
            background-color: transparent;
          "
        >
          <IconWarp :selected="warpingVisible" />
        </div>
        <div
          v-if="warpingVisible"
          style="
            position: absolute;
            top: 130%;
            right: 0%;
            background-color: #ffffff;
            padding: 12px 16px;
            height: min-content;
          "
        >
          <span
            style="
              white-space: nowrap;
              font-size: 12px;
              font-weight: 500;
              margin-right: 24px;
            "
          >
            Warp
          </span>
          <div style="padding: 0.5rem; border: none">
            <input
              v-show="isText()"
              type="range"
              :min="-pageHeight * 1.5"
              :max="pageHeight * 1.5"
              list="warp"
              v-model="activeComponent.warp"
            />
            <datalist id="warp">
              <option :value="0"></option>
            </datalist>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-show="activeComponent" class="edition">
            <div class="arrange-front">
                <IconArrangeFront/>
            </div>
            <div class="arrange-back">
                <IconArrangeBack/>
            </div>
        </div> -->
  </div>
</template>

<script>
/* import IconUndo from "../../assets/icon-undo"
import IconRedo from "../../assets/icon-redo" */
import IconDecreaseFont from '../../assets/icon-decrease-font';
import IconIncreaseFont from '../../assets/icon-increase-font';
import IconBold from '../../assets/icon-bold';
import IconItalic from '../../assets/icon-italic';
import IconUnderlined from '../../assets/icon-underlined';
import IconJustifyLeft from '../../assets/icon-justify-left';
import IconJustifyCenter from '../../assets/icon-justify-center';
import IconJustifyRight from '../../assets/icon-justify-right';
import IconSpacing from '../../assets/icon-spacing.vue';
import IconWarp from '../../assets/icon-warp.vue';
/* import IconArrangeFront from "../../assets/icon-arrange-front"
import IconArrangeBack from "../../assets/icon-arrange-back" */
import { mapGetters } from 'vuex';

export default {
  components: {
    /*         IconUndo,
        IconRedo, */
    IconDecreaseFont,
    IconIncreaseFont,
    IconBold,
    IconItalic,
    IconUnderlined,
    IconJustifyLeft,
    IconJustifyCenter,
    IconJustifyRight,
    IconSpacing,
    IconWarp,
    /*         IconArrangeFront,
        IconArrangeBack, */
  },
  data() {
    return {
      fontFamily: 'Montserrat',
      inputMode: false,
      pageHeight: screen.availHeight - 16 * (4 + 3 + 3.5 + 3 * 5),
      spacingVisible: false,
      warpingVisible: false,
      customTemplate: { 'wallid.io': true },
    };
  },
  methods: {
    isText() {
      return this.activeComponent
        ? this.activeComponent.type === 'text'
        : false;
    },
    isSignature() {
      return this.activeComponent
        ? this.activeComponent.type === 'signature'
        : false;
    },
    showFeature() {
      return (
        this.adminEmail &&
        this.customTemplate[this.getEmail(this.adminEmail).domain]
      );
    },
  },
  computed: {
    ...mapGetters({
      adminEmail: 'adminEmail',
    }),
    activeComponent() {
      return this.$store.getters.activeComponent;
    },
    activeComponentX() {
      return this.$store.getters.activeComponent.x;
    },
    renderedDimensions() {
      return this.$store.getters.renderedDimensions;
    },
    selectedTab() {
      return this.$store.getters.selectedTab;
    },
    layoutBackgroundColor: {
      get() {
        return this.$store.getters.layoutBackgroundColor;
      },
      set(value) {
        this.$store.commit('updateLayoutBackgroundColor', value);
      },
    },
  },
};
</script>

<style scoped>
div {
  display: flex;
  justify-content: center;
  padding: 0.4rem;
  align-items: center;
  background-color: white;
  border: 1px solid #d8d8d8;
  height: 2rem;
  font-weight: 500;
}

.edition {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: visible !important;
}

.toolbar {
  padding: 0 1.5rem;
  background-color: #f1f1f1;
  border: none;
  flex-grow: 1;
  height: 100%;
  min-width: 1024px;
  justify-content: start;
  overflow: visible !important;
}

.undo {
  border-radius: 0.3rem 0 0 0.3rem;
  width: 2rem;
  border-right: none;
}

.redo {
  border-radius: 0 0.3rem 0.3rem 0;
  width: 2rem;
  margin-right: 2rem;
}

.picker {
  border-radius: 0.3rem;
  width: 3rem;
  margin-right: 8px;
}

.font-family {
  justify-content: start;
  border-radius: 0.3rem !important;
  margin-right: 8px;
}

select {
  appearance: menulist;
  width: 15rem;
}

.decrease-font {
  border-radius: 0.3rem 0 0 0.3rem;
  width: 2rem;
  border-right: none;
}

.font-size {
  width: 2rem;
  border-right: none;
}

.increase-font {
  border-radius: 0 0.3rem 0.3rem 0;
  width: 2rem;
  margin-right: 8px;
}

.bold {
  border-radius: 0.3rem 0 0 0.3rem;
  width: 2rem;
}

.italic {
  width: 2rem;
  border-right-color: transparent;
  border-left-color: transparent;
}

.underlined {
  border-radius: 0 0.3rem 0.3rem 0;
  width: 2rem;
  margin-right: 8px;
}

.align-left {
  border-radius: 0.3rem 0 0 0.3rem;
  width: 2rem;
}

.align-center {
  width: 2rem;
  border-right-color: transparent;
  border-left-color: transparent;
}

.align-right {
  border-radius: 0 0.3rem 0.3rem 0;
  width: 2rem;
  margin-right: 8px;
}

.arrange-front {
  border-radius: 0.3rem 0 0 0.3rem;
  width: 2rem;
  border-right: none;
}

.arrange-back {
  border-radius: 0 0.3rem 0.3rem 0;
  width: 2rem;
}

.active {
  background-color: #dbedef;
  border: 1px solid #009fb1;
}

.noRightBorder {
  border-right-color: transparent;
}

.noLeftBorder {
  border-left-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 7rem;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 0;
  cursor: pointer;
  border: 0.1px solid #b8b9bb;
  border-bottom: none;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 3rem;
  background: #009fb1;
  margin-top: -6px;
}
</style>
